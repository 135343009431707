import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import disposeAllInstances from '../../../constants/util';
import { updateOptions } from '../../../state/slice';
import Setting from '../Setting';

export default function Header() {
  const options = useSelector((state: any) => state.option.value);
  const dispatch = useDispatch();
  const handleChange = (value: any) => {
    disposeAllInstances();
    dispatch(updateOptions({ ...options, ...{ reelMode: value.target.checked } }));
  };

  return (
    <Box
      position="fixed"
      sx={{
        backgroundColor: 'white',
        boxShadow: '0 0 3px rgba(0,0,0,.11)',
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        transformOrigin: 'top',
        WebkitTransition: 'all .6s cubic-bezier(.4,.25,0,1)',
        zIndex: '99',
        width: '100%',
      }}
    >
      <Box
        component="img"
        src="https://appinventiv.com/wp-content/themes/twentynineteen-child/images/logo.svg"
        alt="logo"
        width={250}
        height={40}
      />

      <Typography
        sx={{
          color: 'black',
          fontSize: '22px',
          fontWeight: '400',
          flexBasis: 0,
          flexGrow: 1,
          marginLeft: '25%',
        }}
      >
        Video Player Accelerator
      </Typography>
      <Box
        sx={{
          paddingRight: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FormControlLabel
          control={<Switch defaultValue={options?.reelMode} onChange={handleChange} />}
          label="Reel Mode"
        />
        {!options?.reelMode && <Setting />}
      </Box>
    </Box>
  );
}
