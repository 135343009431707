import logo from '../../../../assets/images/appinventiv.jpg';
import './styles.css';

export default function ReelsOverlay({ source, subscribeHandler }: any) {
  return (
    <div className="main-container">
      <div className="profile-container">
        <div className="user-info-container">
          <div>
            <img className="image-outer" width="25px" height="25px" src={logo} alt="logo" />
          </div>
          <div className="text">@Appinventiv</div>
        </div>
        <div className="subscribe">
          <button className="subscribe-button" type="button" onClick={subscribeHandler}>
            Subscribe
          </button>
        </div>
      </div>
      <div className="text">{source.name}</div>
      <div className="description">{source.description}</div>
    </div>
  );
}
