import { useEffect, useState } from 'react';

const ReelsControlHandler = (playerRef: any) => {
  const [muted, setMuted] = useState(true);
  const [paused, setPaused] = useState(true);
  const [hideControls, setHideControls] = useState(true);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    if (playerRef && playerRef.current) {
      setPaused(playerRef?.current?.paused());
      setMuted(playerRef?.current?.muted());

      playerRef?.current?.on('play', () => {
        setPaused(playerRef?.current?.paused());
      });
      playerRef?.current?.on('pause', () => {
        setPaused(playerRef?.current?.paused());
      });
      playerRef?.current?.on('volumechange', () => {
        setMuted(playerRef?.current?.muted());
      });
      playerRef?.current?.on('useractive', () => {
        setVisibility(true);
      });
      playerRef?.current?.on('userinactive', () => {
        setVisibility(false);
      });
    }
  }, [playerRef]);

  const handlePausePlay = () => {
    if (paused) {
      playerRef.current.play();
    } else {
      playerRef.current.pause();
    }
  };

  const handleMuted = () => {
    if (muted) {
      playerRef.current.muted(false);
    } else {
      playerRef.current.muted(true);
    }
  };

  return {
    muted,
    paused,
    hideControls,
    visibility,
    setHideControls,
    handlePausePlay,
    handleMuted,
  };
};
export default ReelsControlHandler;
