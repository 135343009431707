import videojs from 'video.js';

export const disposeAllInstances = () => {
  if (videojs.getPlayers()) {
    const players = videojs.getPlayers();
    const keys = Object.keys(players);
    keys.forEach((element) => {
      if (players[element]) {
        const vp: any = players[element];
        vp.dispose();
      }
    });
  }
};

export default disposeAllInstances;
