import { useState, useEffect, useRef } from 'react';
import { debounce } from '@mui/material/utils';
import { useDispatch } from 'react-redux';
import defaultOptions from '../../../constants/defaultaoptions';
import { updateOptions } from '../../../state/slice';

const settingHandler = () => {
  const [open, setOpen] = useState(false);
  const [options, setvideoOptions] = useState(defaultOptions);
  const dispatch = useDispatch();
  const backwardRef: any = useRef();
  const forwardRef: any = useRef();
  const videoSpeedRef: any = useRef();

  useEffect(() => {
    dispatch(updateOptions(options));
  }, [options]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleControlBar = (event: any) => {
    setvideoOptions({ ...options, ...{ controls: event.target.checked } });
  };

  const handleBackwardControls = (event: any) => {
    setvideoOptions({ ...options, ...{ seekBackwardDisplay: event.target.checked } });
  };

  const handleForwardControls = (event: any) => {
    setvideoOptions({ ...options, ...{ seekForwardDisplay: event.target.checked } });
  };

  const handleVideoSpeedControl = (event: any) => {
    setvideoOptions({
      ...options,
      ...{ playbackRates: event.target.checked ? [0.5, 1, 1.5, 2] : false },
    });
  };

  const handleManualPlaybackRatesControl = (event: any) => {
    setvideoOptions({
      ...options,
      ...{ qualitySelector: event.target.checked },
    });
  };

  const handleFullscreen = (event: any) => {
    setvideoOptions({
      ...options,
      ...{
        controlBar: { ...options.controlBar, fullscreenToggle: event.target.checked },
        userActions: { ...options.userActions, doubleClick: event.target.checked },
      },
    });
  };

  const handlePictureInPicture = (event: any) => {
    setvideoOptions({
      ...options,
      ...{
        disablePictureInPicture: !event.target.checked,
        controlBar: { ...options.controlBar, pictureInPictureToggle: event.target.checked },
      },
    });
  };

  const handleBackward = debounce((event: any) => {
    backwardRef.current.value = event.target.value;
    setvideoOptions({
      ...options,
      ...{
        plugins: {
          ...options.plugins,
          seekButtons: { ...options.plugins.seekButtons, back: Number(event.target.value) },
        },
      },
    });
  }, 700);
  const handleForward = debounce((event: any) => {
    forwardRef.current.value = event.target.value;
    setvideoOptions({
      ...options,
      ...{
        plugins: {
          ...options.plugins,
          seekButtons: { ...options.plugins.seekButtons, forward: Number(event.target.value) },
        },
      },
    });
  }, 700);

  const handleVideoSpeed = debounce((event: any) => {
    setvideoOptions({
      ...options,
      ...{ playbackRates: event.target.value.split(',').map(Number) },
    });
  }, 700);

  const handleTheaterMode = (event: any) => {
    setvideoOptions({ ...options, ...{ theatreMode: event.target.checked } });
  };

  const handlePlayControl = (event: any) => {
    setvideoOptions({
      ...options,
      ...{ controlBar: { ...options.controlBar, playToggle: event.target.checked } },
    });
  };

  const handleVolumeControl = (event: any) => {
    setvideoOptions({
      ...options,
      ...{ controlBar: { ...options.controlBar, volumePanel: event.target.checked } },
    });
  };

  const handleSeekBarControl = (event: any) => {
    setvideoOptions({
      ...options,
      ...{
        controlBar: { ...options.controlBar, progressControl: { seekBar: event.target.checked } },
      },
    });
  };

  const handleRemainingTimeDisplay = (event: any) => {
    setvideoOptions({
      ...options,
      ...{
        controlBar: {
          ...options.controlBar,
          remainingTimeDisplay: event.target.checked ? { displayNegative: false } : false,
        },
      },
    });
  };

  const toggleDrawer = (toggle: boolean) => {
    setOpen(toggle);
  };

  return {
    open,
    handleClickOpen,
    handleClose,
    options,
    handleBackwardControls,
    handleControlBar,
    handleForwardControls,
    handleVideoSpeedControl,
    handleManualPlaybackRatesControl,
    handleFullscreen,
    handlePictureInPicture,
    handleBackward,
    handleForward,
    handleVideoSpeed,
    handleTheaterMode,
    handlePlayControl,
    handleVolumeControl,
    handleSeekBarControl,
    handleRemainingTimeDisplay,
    backwardRef,
    forwardRef,
    videoSpeedRef,
    toggleDrawer,
  };
};
export default settingHandler;
