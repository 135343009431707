import './App.css';
import './assets/styles/index.css';
import Header from './common/components/Header';
import VideoPlayerWrapper from './pages/video-player';
import disposeAllInstances from './constants/util';

function App() {
  disposeAllInstances();
  return (
    <div>
      <Header />
      <VideoPlayerWrapper />
    </div>
  );
}

export default App;
