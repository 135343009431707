import { ReactComponent as LikeImage } from '../../../../assets/images/like.svg';
import { ReactComponent as CommentImage } from '../../../../assets/images/comment.svg';
import { ReactComponent as ShareImage } from '../../../../assets/images/share.svg';
import { ReactComponent as ReportImage } from '../../../../assets/images/report.svg';
import './styles.css';

export default function ReelsSideMenu({
  likeHandler,
  commentHandler,
  shareHandler,
  reportHandler,
}: any) {
  return (
    <div className="side-menu-container">
      <div className="action-container">
        <div className="image-container" onClick={likeHandler}>
          <LikeImage className="image" />
        </div>
        <div>50</div>
      </div>
      <div className="action-container">
        <div className="image-container" onClick={commentHandler}>
          <CommentImage className="image" />
        </div>
        <div>100</div>
      </div>
      <div className="action-container">
        <div className="image-container" onClick={shareHandler}>
          <ShareImage className="image" />
        </div>
        <div>&nbsp;</div>
      </div>
      <div className="action-container">
        <div className="image-container" onClick={reportHandler}>
          <ReportImage className="image" />
        </div>
        <div>&nbsp;</div>
      </div>
    </div>
  );
}
