import './styles.css';
import { ReactComponent as Play } from '../../../../assets/images/play.svg';
import { ReactComponent as Pause } from '../../../../assets/images/pause.svg';
import { ReactComponent as Volume } from '../../../../assets/images/volume.svg';
import { ReactComponent as Muted } from '../../../../assets/images/muted.svg';
import ReelsControlHandler from './handler';

export default function ReelsControls({ playerRef }: any) {
  const { muted, paused, hideControls, setHideControls, handlePausePlay, handleMuted, visibility } =
    ReelsControlHandler(playerRef);

  return (
    <div
      className={visibility ? 'controls-container' : 'controls-inactive'}
      onMouseLeave={() => {
        setHideControls(true);
      }}
    >
      {hideControls && (
        <>
          <div className="top-controls">
            <div
              onClick={() => {
                handlePausePlay();
              }}
              className="play"
            >
              <div className="image-size">{paused ? <Play /> : <Pause />}</div>
            </div>
            <div
              className="volume"
              onClick={() => {
                handleMuted();
              }}
            >
              <div className="image-size">{muted ? <Muted /> : <Volume />}</div>
            </div>
          </div>
          <div
            className="mid-controls"
            onClick={() => {
              handlePausePlay();
            }}
          >
            <div className="image-size">{paused ? <Play /> : <Pause />}</div>
          </div>
        </>
      )}
    </div>
  );
}
