import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Drawer,
  IconButton,
  Divider,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import settingHandler from './handler';

export default function Setting() {
  const {
    open,
    options,
    handleBackwardControls,
    handleControlBar,
    handleForwardControls,
    handleVideoSpeedControl,
    handleManualPlaybackRatesControl,
    handleFullscreen,
    handlePictureInPicture,
    handleBackward,
    handleForward,
    handleVideoSpeed,
    handleTheaterMode,
    handlePlayControl,
    handleVolumeControl,
    handleSeekBarControl,
    handleRemainingTimeDisplay,
    backwardRef,
    forwardRef,
    videoSpeedRef,
    toggleDrawer,
  } = settingHandler();

  const flexChild = {
    flex: '1 1 25%',
  };

  return (
    <>
      <SettingsOutlinedIcon
        sx={{ color: '#0092ff' }}
        onClick={() => {
          toggleDrawer(true);
        }}
      />

      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          toggleDrawer(false);
        }}
      >
        <Box component="div" height="50px">
          <IconButton
            onClick={() => {
              toggleDrawer(false);
            }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box sx={{ width: 250 }}>
          <FormGroup
            sx={{ display: 'flex', flexDirection: 'column', gap: '20px', marginLeft: '20px' }}
          >
            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.controls}
                  onChange={handleControlBar}
                  name="controlBar"
                  disabled={options.reelMode}
                />
              }
              label="Contol Bar"
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.controlBar?.playToggle}
                  onChange={handlePlayControl}
                  name="playControl"
                />
              }
              label="Play Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.controlBar?.volumePanel}
                  onChange={handleVolumeControl}
                  name="volumeControl"
                />
              }
              label="Volume Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.controlBar?.progressControl?.seekBar}
                  onChange={handleSeekBarControl}
                  name="seekBarControl"
                />
              }
              label="Seek Bar Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={typeof options.controlBar?.remainingTimeDisplay === 'object'}
                  onChange={handleRemainingTimeDisplay}
                  name="remainingTimeDisplay"
                />
              }
              label="Remaining Time Display Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.seekBackwardDisplay}
                  onChange={handleBackwardControls}
                  name="backwardControls"
                />
              }
              label="Backward Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.seekForwardDisplay}
                  onChange={handleForwardControls}
                  name="forwardControls"
                />
              }
              label="Forward Control"
              disabled={options.reelMode || options.controls === false}
            />

            <TextField
              sx={flexChild}
              label="Backward Interval(In seconds)"
              defaultValue={options.plugins.seekButtons.back || 10}
              variant="standard"
              onChange={handleBackward}
              ref={backwardRef}
              disabled={options.reelMode || options.controls === false}
            />

            <TextField
              sx={flexChild}
              label="Forward Interval(In seconds)"
              defaultValue={options.plugins.seekButtons.forward || 10}
              variant="standard"
              onChange={handleForward}
              ref={forwardRef}
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={typeof options.playbackRates === 'object'}
                  onChange={handleVideoSpeedControl}
                  name="videoSpeedControl"
                />
              }
              label="Video Speed Control"
              disabled={options.reelMode || options.controls === false}
            />

            <TextField
              sx={flexChild}
              label="Video Speed(Comma Separated Numbers like 0.5,1,1.5,2)"
              defaultValue={options.playbackRates.toString()}
              variant="standard"
              onChange={handleVideoSpeed}
              ref={videoSpeedRef}
              disabled={options.reelMode || options.controls === false}
            />
            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={!options.disablePictureInPicture}
                  onChange={handlePictureInPicture}
                  name="pictureInPicture"
                />
              }
              label="Picture In Picture"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.qualitySelector}
                  onChange={handleManualPlaybackRatesControl}
                  name="manualPlaybackRatesControl"
                />
              }
              label="Manual Playback Rates Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.controlBar.fullscreenToggle}
                  onChange={handleFullscreen}
                  name="fullscreen"
                />
              }
              label="Fullscreen Control"
              disabled={options.reelMode || options.controls === false}
            />

            <FormControlLabel
              sx={flexChild}
              control={
                <Checkbox
                  checked={options.theatreMode}
                  onChange={handleTheaterMode}
                  name="theatreMode"
                />
              }
              label="Theatre Mode Control"
              disabled={options.reelMode || options.controls === false}
            />
          </FormGroup>
        </Box>
      </Drawer>
    </>
  );
}
