import axios from 'axios';
import axiosRetry from 'axios-retry';
import Env from '../constants/env';
// get session token
// const accessToken: string = '';
axiosRetry(axios, { retries: 3 });

// axios instance for api methods
export default axios.create({
  baseURL: `${Env.API_BASE_URL}/`,
  headers: {
    'Content-type': 'application/json',
    // Authorization: accessToken,
  },
});

// axios.interceptors.request.use(
//   (config: any) => {
//     const token = localStorage.getItem('access_token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error: any) => {
//     Promise.reject(error);
//   },
// );
