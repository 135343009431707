const styles = {
  videoContainer: {
    flexWrap: 'wrap',
    paddingTop: '90px',
    paddingLeft: '20px',
    height: '70vh',
    // width: '60%',
  },
  reelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '90px',
    gap: '30px',
    scrollSnapType: 'y mandatory',
    overflow: 'scroll',
    height: '100vh',
    scrollPaddingTop: '90px',
    scrollPaddingBottom: '90px',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    MsOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none' /* Firefox */,
  },
};

export default styles;
