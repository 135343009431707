import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import Header from '../common/components/Header';
import VideoPlayer from '../modules/Video/components';
import playerHandler from './handler';
import styles from './styles';

function VideoPlayerWrapper() {
  const options = useSelector((state: any) => state.option.value);
  const { playList, handlePlayerReady, handleReelPlayerReady, handleFirstReelPlayerReady } =
    playerHandler();

  return (
    <>
      <Header />
      {!options?.reelMode && (
        <Box sx={styles.videoContainer}>
          {options && (
            <VideoPlayer options={options} source={playList} onReady={handlePlayerReady} />
          )}
        </Box>
      )}
      {options?.reelMode && playList.length > 0 && (
        <Box sx={styles.reelsContainer} className="scroll-container">
          {playList.map((element: any, index: number) => (
            <Box
              height="calc(100vh - 160px)"
              width="52vh"
              sx={{
                scrollSnapAlign: 'start',
                scrollSnapStop: 'always',
              }}
              key={Math.random().toString(36).substring(2, 10)}
            >
              <VideoPlayer
                options={options}
                source={element}
                onReady={index === 0 ? handleFirstReelPlayerReady : handleReelPlayerReady}
                key={Math.random().toString(36).substring(2, 10)}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
}

export default VideoPlayerWrapper;
